/* Custom rectangular pagination bullets */
.swiper-pagination-bullet {
    width: 20px;            /* Rectangle width */
    height: 3px;            /* Rectangle height */
    border-radius: 4px;     /* Slightly rounded corners */
    background-color: #ffffffe3; /* Default bullet color */
    opacity: 0.7;           /* Transparency */
    transition: background-color 0.3s ease;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #fff; /* Active bullet color */
    opacity: 1;
  }
  
  .mySwiper .swiper-pagination {
   
    margin-bottom: 20px;   /* Add space between slides and pagination */

  }